.p-breadcrumb {
  background: var(--color-white);
  border: none;
  border-radius: 0.375rem;
  padding: 1rem;
  margin-bottom: 4rem;
  padding-left: 0;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.p-breadcrumb ul li .p-menuitem-link {
  transition: all 0.2s;
  &:hover {
    text-decoration: underline;
  }
}
