.p-datatable .p-datatable-thead > tr > th {
  padding: 1.5rem 1rem;
  // background: transparent;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bolder;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 0.5em !important;
}

.p-datatable .p-datatable-tbody > tr {
  border: 1px solid var(--color-success) !important;
  // background: transparent;
}

.p-datatable .p-datatable-tbody > tr td {
  font-size: 1.3rem;
  // padding: 0.3rem 1rem;
  padding: 1rem;
}

// TABVIEW
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: var(--color-main);
  color: var(--color-main);
}
