
.p-message {
  & .p-message-wrapper {
    flex-direction: column;
    align-items: flex-start;
    border-left: 3px solid;
  }

  & .p-message-icon {
    margin-bottom: 1.2rem;
    font-size: 2rem;
  }

  & .p-message-detail {
    margin-left: 0;
  }

  & .p-message-summary {
    margin-bottom: .8rem;
  }
}
