@forward "../variables";
@forward "./common";
@forward "./button";
@forward "./form";
@forward "./breadcrumb";
@forward "./toast";
@forward "./sidebare";
@forward "./tag";
@forward "./table";
@forward "./paginator";
@forward "./date";
@forward "./messages";
@forward "./toolbar";

.p-message {
  border-radius: 0;
}
