*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: var(--ff);
  font-size: var(--fs);
  font-weight: var(--fw-md);
  color: var(--color-black);
  background: var(--color-white);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

[hidden] {
  display: none !important;
}

.empty-table-message-block {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
}

.main-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  background: var(--color-white);
  width: 100%;
  height: 100%;
  z-index: 9999;

  h2 {
    margin-top: 2rem;
  }
}

.content-wrapper {
  padding: 10rem;
  // padding-top: 5rem;
}
