
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
.p-datepicker table th>span,
.p-datepicker table td>span {
  font-size: 1.2rem;
}

.p-datepicker .p-datepicker-buttonbar .p-button {
  color: var(--color-main);
}
