// ==== BUTTON =====
.p-button {
  background: var(--color-main);
  border: 1px solid var(--color-main);
  padding: 1.7rem 1.95rem;
  border-radius: 0px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
  display: inline-block;
  cursor: pointer;

  &.rounded {
    border-radius: 5px;
    padding: 1rem 1.85rem;
    text-transform: initial;
  }

  &.p-button-sm {
    font-size: 1.3rem;
    padding: 1.25rem 1.95rem;

    &.rounded {
      border: 4px;
    }
  }

  &:hover {
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.45;

    &:hover {
      background-color: none;
    }
  }

  &.p-button-white {
    background: var(--color-white);
    color: var(--color-main);
    border: 1px solid var(--color-white);
    transition: all 0.2s;

    &:hover {
      border: 1px solid var(--color-white);
      color: var(--color-main);
      background: rgba(255, 255, 255, 0.75);
    }
  }

  &.p-button-second {
    background: var(--color-second);
    border: 1px solid var(--color-second);
    color: var(--color-white);
  }

  &.p-button-success {
    background: var(--color-success);
    border: 1px solid var(--color-success);
  }

  &.p-button-warning {
    background: var(--color-warning);
    border: 1px solid var(--color-warning);
  }

  &.p-button-danger {
    background: var(--color-danger);
    border: 1px solid var(--color-danger);
  }

  // ======= OUTLINED =====
  &.p-button-outlined {
    background-color: transparent;
    color: var(--color-main);
    border: 1px solid;
    font-weight: var(--fw-bd);
    transition: background 0.2s;

    &:enabled:hover {
      background: rgba(79, 70, 229, 0.04);
      color: var(--color-main);
      // border: 1px solid;
    }

    &.p-button-secondary {
      border: 1px solid var(--color-secondary);
      color: var(--color-black);
      font-weight: bolder;

      &:hover {
        // background-color: red;
      }
    }

    &.p-button-second {
      border: 1px solid var(--color-second);
      color: var(--color-second);
      font-weight: bolder;

      &:hover {
        background-color: var(--color-second);
        color: var(--color-white);
      }
    }

    &.p-button-white {
      border: 1px solid var(--color-white);
      color: var(--color-white);
      transition: all 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.11);
        color: var(--color-white);
        // border: 1px solid var(--color-white);
      }
    }
  }
}
// *******  END *******
