.p-tag {
  background: var(--color-main);
  font-size: 1.2rem;
}

.p-divider {
}

.p-divider.p-divider-horizontal {
  margin-bottom: 4rem;
}

.p-chip {
  border-radius: 5px;
  font-size: 1.1rem;
}
