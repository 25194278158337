.p-toast .p-toast-message {
  border-left: 4px solid;

  &.p-toast-message-success {
    color: var(--color-success);
  }
  &.p-toast-message-main {
    color: var(--color-main);
  }
  &.p-toast-message-warning {
    color: var(--color-warning);
  }
  &.p-toast-message-error {
    color: var(--color-danger);
  }
}
