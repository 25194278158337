.p-field {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &.p-field-column {
    flex-direction: column;

    & > * {
      flex: 1;
    }

    .p-input-icon-left,
    .p-inputnumber,
    .p-inputmask,
    .p-dropdown,
    .p-calendar {
      width: 100%;
    }
  }

  &-actions {
    justify-content: flex-end;
    align-items: center;
    margin-top: 4rem;

    &.full {
      & > * {
        width: 100%;
      }

      // button .p-button-label {
      //   display: none !important;
      // }
    }

    &.loader {
      justify-content: space-between;
    }
  }

  &-label {
    margin-bottom: 0.5rem;
    color: var(--color-black);

    &.flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-help {
    margin-top: 0.6rem;
    font-weight: var(--fw-sm);
    color: var(--color-danger);
  }

  &-radiobutton {
    display: flex;
    align-items: center;
    label {
      margin-left: 0.5rem;
    }
  }

  &-image {
    display: flex;
    margin-top: 4rem;
    margin-bottom: 4rem;

    &__description {
      flex: 1;
      margin-left: 3rem;
      font-size: 1.2rem;
      // display: flex;
      // align-items: center;

      ul {
        // list-style: disc;
        margin-top: 2rem;
      }

      ul li {
        // padding-left: 1rem;
      }

      ul li:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }
  }
}

.p-inputtext {
  padding: 1.35rem;
  border: 1px solid var(--color-light);
  color: var(--color-black);
  border-radius: 0px;

  // &.ng-invalid {
  //   border: 1px solid var(--color-danger);
  // }

  &.rounded {
    padding: 1.15rem 1.2rem;
    border-radius: 5px;
  }
}

.p-inputtext[readonly] {
  background: var(--color-light);
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--color-main);
  border-color: var(--color-main);
}

.p-multiselect {
  padding: 0.45rem 0.65rem;
  border-radius: 0px;

  &.rounded {
    border-radius: 6px;
  }

  &-filter {
    padding: 0.8rem;
  }
}

.p-dropdown {
  padding: 0.3rem 0.35rem;
  border-radius: 0px;

  &.rounded {
    border-radius: 6px;
  }

  &.p-component {
    padding: 0;
  }

  &-label {
    font-family: var(--ff);
    font-size: var(--fs);
  }

  &:not(.p-disabled).p-focus {
    border-color: var(--color-main);
    box-shadow: 0 0 0 1px var(--color-main);
  }
}

.p-dropdown-panel {
  border-radius: 0;

  &.rounded {
    border-radius: 5px;
  }
}

.p-calendar.p-calendar-w-btn {
  &.rounded {
    .p-calendar-w-btn .p-inputtext {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .p-calendar-w-btn .p-datepicker-trigger {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--color-main);
  background: #eef2ff;
}

// RADIO BUTTON
.p-radiobutton {
  width: 20px;
  height: 20px;
}

.p-radiobutton:not(.p-radiobutton-disabled) .p-radiobutton-box.p-focus {
  box-shadow: 0 0 2px #fff, 0 0 4px var(--color-main),
    0 1px 2px 0 rgb(0 0 0 /0%);
}

.p-radiobutton .p-radiobutton-box {
  width: 20px;
  height: 20px;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 10px;
  height: 10px;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--color-main);
  background: var(--color-main);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  border-color: var(--color-main);
}

// FIle upload
.p-fileupload .p-fileupload-buttonbar .p-button {
  font-size: 1.2rem;
  padding: 0.8rem;
}

// CHECKBOX
.p-field-checkbox-container {
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.p-field-checkbox {
  label {
    margin-left: 1rem;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: var(--color-main);
  border-color: var(--color-main);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: var(--color-main);
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--color-main),
    0 1px 2px 0 rgb(0 0 0 /0%);
}

input[type="password" i] {
  width: 100%;
}

.p-password {
  width: 100%;
  .p-inputtext {
    width: 100%;
  }

  &-panel {
    border-radius: 0;

    &.rounded {
      border-radius: 5px;
    }
  }
}

// FORM SECTION
.form-section {
  &:not(:last-child) {
    margin-bottom: 5rem;
  }

  &-title {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    font-weight: var(--fw-bd);
    color: var(--color-main);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      width: 60%;
      background: var(--color-light);
    }
  }
}

.form-inline {
  display: flex;
  align-items: center;

  & > :first-child {
    flex: 1;
  }

  & > :not(:last-child) {
    margin-right: 2rem;
  }
}


.p-input-icon-left {
  .p-inputtext {
    padding-left: 4rem;
  }

  i {
    font-size: 1.5rem;
    padding-left: .5rem;
  }
}


.field-radiobutton {
  label {
    margin-left: .3rem;
  }
}
