// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400;700&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@300;400;700&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Spartan:wght@200;300;400;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lexend:wght@200;300;400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;600&display=swap');

:root {
  --color-black: #515151;
  --color-main: #1c2e82;
  --color-main-light: rgba(28, 47, 130, 0.2);
  --color-main-lighter: rgba(28, 47, 130, 0.1);
  --color-second: #ffbf63;
  --color-second: rgba(240, 129, 10, 0.5);
  --color-white: #f1f5f9;
  --color-white-light: hsla(210, 40%, 96%, 0.5);
  --color-success: #519259;
  --color-success-light: rgba(81, 146, 89, 0.2);
  --color-danger: #e63946;
  --color-danger-light: rgba(230, 57, 71, 0.2);
  --color-warning: #fb8500;
  --color-warning-light: rgba(251, 134, 0, 0.2);
  --color-light: #e5e5e5;
  --color-secondary: #b1b1b1;
  --color-secondary-light: rgba(177, 177, 177, 0.2);
  --color-info: #3bc7fa;
  --color-info-light: rgba(59, 199, 250, 0.2);

  --bg-chip: #eef2ff;

  // FONT
  // --ff: "Cascadia Code", sans-serif;
  // --ff: 'Spartan', sans-serif;
  --ff: "Work Sans", sans-serif;
  --ff-mono: "Inconsolata", sans-serif;
  --fw-sm: "300";
  --fw-md: "400";
  --fw-bd: "700";
  --fs: 1.4rem;

  // SIZE
  --b-pad: 1.25rem 1.75rem;
}

// ====== COLOR =========
$color-main: #1c2e82;
$color-white: #f1f5f9;
$color-success: #519259;
$color-danger: #e63946;
$color-warning: #fb8500;
$color-black: #515151;
