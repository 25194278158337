// ############# EXAM REGISTRATION CHECK ###########
.content-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100vh;

    &__main{
        max-width: 576px;
    }

    &__card-check-paiement{
        min-height: 15rem;
        padding: 1.5rem 0;
        background-color: white;
        box-shadow: 1px 1px 16px #dcddde80;

        .box-input-search{
            display: flex;
            align-items: center;
            height: 5rem;
            margin: 0 1rem;
            padding: 0 1rem;
            background-color: var(--color-white);

            i{
                font-size: 1.5rem;
            }

            input{
                flex: 1;
                height: 100%;
                border: none;
                background: transparent;
                padding: 0 1rem;
                color: var(--color-black);

                &:focus{
                    outline: none;
                }
            }

            .btn-virify-paiement{
                padding: .9rem 2rem;
                color: white;
                background: #1c2f8291;
                border: 1px solid transparent;
                cursor: pointer;
                &:focus{
                    outline: none;
                }
            }
        }
    }

    .divider{
        border: 1px solid transparent;
        height: 1px;
        background-color: var(--color-light);
        margin: 1.5rem 0;
    }

    &__box-result-paiement{
        padding: 0 1rem;

        & h3{
            color: #515151a2;
            margin-bottom: .7rem;
        }

        .detail-info{
            background-color: var(--color-main-light);
            padding: .1rem .5rem;
            margin: 0 1rem 0 .5rem;
            border-radius: .2rem;
        }

        .result-info{
            background-color: #fb86009d;
            padding: .1rem .5rem;
            border-radius: .2rem;
        }

        .link-tresor-pay{
            color: var(--color-main);
            text-decoration: underline;
        }

        .icon-success{
            color: var(--color-success);
            font-size: 1.5rem;
            font-weight: var(--fw-bd);
        }
    }
}
// ############# END ##############
