.examen-section {
  padding: 5rem 20rem;
}

.examen-section__header {
  width: 100%;
  max-width: 856px;
  margin: 0 auto;
  margin-bottom: 4rem;
}

.examen-section__content {
  max-width: 450px;
  width: 50%;
  margin: 0 auto;

  &.full {
    width: 756px;
    max-width: 100%;
    margin: 0 auto;
  }

  h3 {
    font-size: 2.8rem;
    margin-bottom: 0.7rem;
    text-align: center;
  }

  p {
    font-size: 1.4rem;
    font-weight: var(--fw-sm);
    line-height: 1.5;
    text-align: center;
  }

  form {
    margin-top: 4rem;
  }
}

.examen-action-block {
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
}

.examen-section__document {
  width: 100%;
  height: 700px;
  margin: 4rem 0rem;
}

.student-info-loader {
  margin-top: 5rem;
  text-align: center;

  p {
    margin-bottom: 2rem;
    font-size: 1.8rem;

    span {
      font-weight: var(--fw-bd);
      color: var(--color-main);
    }
  }
}

.examen-section__registred {
  display: flex;

  p-card {
    width: 200px;
    margin-right: 4rem;
  }

  &-item {
    display: flex;
    flex-direction: column;

    .title {
      text-transform: uppercase;
      // color: var(--color-light);
      margin-bottom: 0.5rem;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

.examen-section__registred-detail {
  flex: 1;
  &-item {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-light);
    }

    .content {
      text-align: right;

      &.file {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        color: var(--color-danger);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        .pi-file-pdf {
          color: var(--color-danger);
          font-size: 1.6rem;
        }
      }
    }
  }
}


.exam-step {
  display: flex;
  align-items: stretch;
  width: 100%;
  // height: 7rem;

  &__close {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--color-main-light);
    transition: background .2s;

    &:hover {
      background: var(--color-main-light);
    }

    i {
      font-size: 2rem;
    }
  }

  &__finish {
    background: var(--color-main);
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4rem;
    box-sizing: border-box;

    &.disabled {
      opacity: .45;
      cursor: not-allowed;
      color: var(--color-light);
    }

    img {
      width: 120px;
    }

    span {
      margin-right: 2rem;
    }

    i {
      font-size: 1.6rem;
    }
  }

  &__list {
    flex: 1;
    height: 100%;
    display: flex;
    background: #fff;
  }

  &__list-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.75rem 3.5rem;
    border: 1px solid var(--color-main-light);
    opacity: .15;

    &.active {
      opacity: 1;
      border-bottom: 2px solid var(--color-main);
    }

    &.complete {
      opacity: 1;

      .title {
        text-decoration: line-through;
      }
      .status {
        color: var(--color-success);
      }

      .icon {
        i {
          display: inline-block;
        }

        span {
          display: none;
        }
      }
    }

    .detail {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .status {
      margin-bottom: 1rem;
    }

    .title {
      // margin-bottom: .5rem;
      font-size: 1.7rem;
      font-weight: var(--fw-bd);
      color: var(--color-main);
    }

    .icon {
      i {
        font-size: 2.5rem;
        color: var(--color-success);
        display: none;
      }

      span {
        display: inline-block;
      }

      img {
        width: 100px;
      }
    }
  }
}


.steps-wrapper {
  width: 100%;
  max-width: 576px;
  margin: 0 auto;
  margin-top: 5rem;

  &.full {
    margin: 0 auto;
    width: 100%;
    max-width: 1120px;
  }
}

.steps-title {
  margin-bottom: 2rem;

  small {
    display: inline-block;
    margin-bottom: 2rem;
  }
}
