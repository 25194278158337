// ************* Common *************
.dashboard-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--color-main);
  font-weight: var(--fw-bd);
}

.dashboard-section {
  margin-bottom: 3rem;
  &-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  &-filter {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 3rem;
  }

  &__group {
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__header {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dashboard-section-input {
      width: 330px;

      .p-multiselect {
        width: 100%;
      }
    }
  }

  &-input {
    display: flex;
    flex-direction: column;
  }

  &-action {
    .chip:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.dashboard-section-sidebar {
  // height: 100%;
}

.dashboard-loader {
  position: fixed;
  left: 250px;
  top: 6rem;
  background-color: var(--color-white);
  width: calc(100% - 250px);
  height: calc(100% - 6rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

// ************* DASHBOARD WRAPPER ***********
.dashboard-wrapper-grid {
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-template-areas: "main sidebar";
  gap: 4rem;

  .dashboard-wrapper-grid-main {
    grid-area: main;
  }

  .dashboard-wrapper-grid-sidebar {
    grid-area: sidebar;
  }
}

.dashboard-wrapper-grid-sidebar-item {
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-light);
  }

  &-value {
    font-weight: 700;
    opacity: 0.75;
  }

  &-value,
  &-label {
    font-weight: 500;
  }
}

// ************* Dashboard validation ********
.dashboard-validation-grid {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: min-content 1fr;
}

.dashboard-validation-sidebar {
  grid-column: 1/2;
  grid-row: 1/-1;
  min-height: 100vh;
  color: var(--color-white);
  border-right: 1px solid var(--color-light);
  background: var(--color-white);
  // background-color: #fff;
}

.dashboard-validation-content {
  grid-column: 2/-1;
  grid-row: 2/-1;
  background: var(--color-white);
  padding: 0rem 10rem;
}

// ************* DASHBOARD SECTION BLOCK *********
.dashboard-section-block {
  display: flex;
  &-item {
    padding: 2rem;
    width: 33.33%;
    background-color: #fff;

    &:not(:last-child) {
      margin-right: 4rem;
    }
  }

  &-item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;

    span {
      font-size: 1.7rem;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

// *********** DASHBOARD VALIDATION SIDEBAR **********
.validation-sidebar__logo {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 1.5rem;
  padding-top: 2rem;

  img {
    width: 150px;
    object-fit: cover;
  }
  small {
    color: var(--color-main);
    font-size: 1.5rem;
    text-transform: uppercase;
  }
  // border-bottom: 2px solid var(--color-white);
  margin-bottom: 2rem;
}

.validation-sidebar__menu {
  width: 100%;
}

.validation-sidebar__group {
  width: 100%;
}

.validation-sidebar__group-title {
  color: var(--color-black);
  padding: 1.5rem;
  padding-bottom: 1rem;
  opacity: 0.7;
  display: flex;
  margin-left: 1rem;
  align-items: center;
  // margin-bottom: 1rem;
  justify-content: space-between;

  i {
    font-size: 1.6rem;
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.validation-sidebar__item {
  width: 100%;
  // padding: 0 2rem;
}

.validation-sidebar__link {
  // color: var(--color-main);
  color: var(--color-black);
  font-weight: var(--fw-bd);
  font-size: 1.3rem;
  padding: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  // border-radius: 8px;

  &.active {
    background-color: rgba(28, 47, 130, 0.1);
    // background-color: var(--color-white);
    border-left: 4px solid var(--color-main);
    color: var(--color-main);
    // border-top-right-radius: 10rem;
    // border-bottom-right-radius: 10rem;
    // box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.074);
  }

  i {
    font-size: 1.6rem;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  span {
    // font-size: 1.6rem;
  }
}

.validation-sidebar__separator {
  height: 1px;
  width: 100%;
  background-color: var(--color-light);
  margin: 1.5rem 0;
}

// *********** DASHBOARD VALIDATION NAVBAR **********
// .dashboard-validation-navbar {
// }

.validation-navbar {
  grid-column: 2/4;
  grid-row: 1/2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 4rem;
  // padding-top: rem;
  border-bottom: 1px solid var(--color-light);
  margin-bottom: 3rem;

  &__search {
    flex-basis: 400px;

    input {
      width: 100%;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
  }

  &__item {
    position: relative;
    padding: 2rem 0rem;
    &:not(:last-child) {
      margin-right: 2.5rem;
    }

    &:last-child {
      padding-right: 3rem;
      border-right: 2px solid var(--color-secondary);
    }
  }

  &__link {
    position: relative;
    color: var(--color-black);
    .pi {
      font-size: 2.5rem;
    }

    span {
      position: absolute;
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background-color: var(--color-danger);
      color: var(--color-white);
      font-size: 1.6rem;
      text-align: center;
      top: -2.2rem;
      left: 1rem;
      line-height: 1.6;
    }
  }
}

.validation-navbar__dropdown {
  position: relative;
  padding: 2rem 0rem;
  &-label {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-text {
      display: flex;
      flex-direction: column;

      span {
        font-size: 1.2rem;
        opacity: .57;
        text-transform: lowercase;
      }
    }

    .pi {
      font-size: 1.2em;
      margin-left: 1rem;
    }
  }

  &:hover {
    .validation-navbar__dropdown-menu {
      display: flex;
    }
  }

  &-menu {
    display: none;
    z-index: 15;
    background: #fff;
    align-items: center;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 100%;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }

  &-item {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 1px solid var(--color-secondary);
    }
  }

  &-link {
    padding: 1rem 3rem 1rem 2rem;
    color: var(--color-black);
    font-size: 1.2rem;
    display: flex;
    justify-content: flex-start;
    font-family: var(--ff);
    z-index: 33;
    transition: all 0.2s;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f6;
      // color: var(--color-main);
    }

    .pi {
      margin-right: 1rem;
      font-size: 1.4rem;
    }
  }
}

// *****************************

// ********* STATISTICS *********
.dashboard-validation__statistics {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-validation__statistics-item {
  background: #fff;
  padding: 3rem;
  flex: 1;
  // border-top: 3px solid;
  // border-color: var(--color-main);
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      height: 50%;
      width: 2px;
      background: var(--color-light);
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &-warning {
    border-color: var(--color-warning);

    .dashboard-validation__statistics-value {
      color: var(--color-warning);
    }
  }

  &-danger {
    border-color: var(--color-danger);

    .dashboard-validation__statistics-value {
      color: var(--color-danger);
    }
  }

  &-success {
    border-color: var(--color-success);

    .dashboard-validation__statistics-value {
      color: var(--color-success);
    }
  }
}

.dashboard-validation__statistics-value {
  font-size: 6rem;
  color: var(--color-main);
  margin-right: 2rem;

  &.warning {
    color: var(--color-warning);
  }

  &.success {
    color: var(--color-success);
  }

  &.danger {
    color: var(--color-danger);
  }
}

.dashboard-validation__statistics-text {
  word-break: break-word;
  display: flex;
  align-items: flex-start;
}

.dashboard-validation__statistics-label {
  font-size: 1.4rem;
  // word-break: break-word;
  color: var(--color-main);
  font-weight: 400;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
}

.dashboard-validation__statistics-link {
  .pi {
    font-size: 2rem;
    color: var(--color-main);
  }
}

// **************** VALIDATION DETAIL *****************
.validation-detail {
  padding: 5rem 20rem;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-around;
}

.validation-detail-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

// candidature
.validation-detail-item {
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  // box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  padding: 4rem;
  margin: 2rem;
  flex: 1 1 33.33%;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &-title {
    font-size: 1.4rem;
    font-weight: var(--fw-bd);
    display: block;
    // border-bottom: 1px solid var(--color-main);
    // background: var(--color-main);
    color: var(--color-main);
    padding: 1rem;
    display: flex;
    align-items: center;
    // border-bottom: 2px solid var(--color-main);

    i {
      font-size: 1.5rem;
      margin-right: 2rem;
    }
  }

  &-photo {
    width: 150px;
    height: 250px;
    // width: 30%;
    margin-right: 5rem;

    img {
      width: 100%;
      // width: 70%;
      object-fit: cover;
      height: 100%;
    }
  }

  &.picture {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 4rem;
    padding: 0;
    // background: var(--color-white);
    box-shadow: none;
  }
}

.validation-detail-header-group {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
}

.validation-detail-header-item {
  display: flex;
  flex-direction: column;
  & > *:not(last-child) {
    margin-bottom: 0.5rem;
  }

  span:nth-child(2) {
    color: var(--color-main);
    font-weight: bold;
    font-size: 1.8rem;
  }
}

.validation-detail-content {
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    // background: var(--color-white);
    background: #fff;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-light);
    }

    span {
      font-size: 1.3rem;
    }

    span:nth-child(2) {
      text-align: right;
      font-weight: bolder;
      color: var(--color-main);
    }
  }
}

.p-sidebar-close-icon {
  font-size: 4rem;
  padding: 4rem;
}

.validation-detail-item-action {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  box-shadow: none;
}

.validation-detail-item-main-content {
  display: flex;

  & > * {
    flex: 1;
    width: 50%;
  }
}

.validation-detail-item-inner {
  display: flex;
  flex-direction: column;
}

// ************* FILTER *****************

.dashboard-section-filter-transparent,
.dashboard-section-filter {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 2rem;
  margin-bottom: 2rem;
}

.dashboard-section-filter-transparent {
  background: transparent;
  padding: 0;
}

.dashboard-section-filter-search {
  flex-basis: 400px;
  margin-right: 8rem;

  & > input {
    width: 100%;
  }
}

.dashboard-section-filter-actions {
  display: flex;
  align-items: center;

  & > * {
    // width: 300px;

    & > .p-dropdown {
      width: 100%;
    }
  }

  & > *:not(:last-child) {
    margin-right: 2rem;
  }
}

// **************************************

// ***************** REGIANAL DIRECTION **********

// .dashboard-section {
//   display: flex;

//   & > * {
//     flex: 1;
//   }

//   .dashboard-section-block-card:not(:last-child) {
//     margin-right: 4rem;
//   }
// }

.dashboard-section-block-card {
  background-color: #fff;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;

  i {
    font-size: 8rem;
    font-weight: lighter;
    margin-bottom: 3rem;
  }

  h2 {
    font-size: 1.5rem;
    i {
      font-size: 2rem;
    }
  }
}

// Code wrapper
.code-wrapper {
  background: #fff;
  padding: 2rem;
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
}

.code-search {
  width: 100%;
  border-bottom: 1px solid var(--color-light);
  padding-bottom: 2rem;
  display: flex;
  align-items: stretch;

  & > *:first-child {
    flex: 1;
  }

  p-inputmask input {
    flex: 1;
    width: 100%;
  }
}

.code-result {
  display: flex;
  align-items: center;
  justify-content: center;

  &-value {
    text-align: center;
  }

  &-value > span {
    font-size: 5rem;
    letter-spacing: 5px;
    color: var(--color-main);
  }
}
