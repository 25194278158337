.account-container {
  height: 100vh;
  width: 100%;
}

.account-image {
  // background: linear-gradient(
  //     to right bottom,
  //     rgba($color: #1c2e82, $alpha: 0.85) 100%,
  //     rgba($color: #1c2e82, $alpha: 0.85) 100%
  //   ),
  //   url("/assets/bg-image.jpg");
  background: linear-gradient(
      to right bottom,
      rgba($color: #1c2e82, $alpha: 0.85) 100%,
      rgba($color: #1c2e82, $alpha: 0.85) 100%
    ),
    url("/assets/bg-image.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.account-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // place-items: center;
  margin-top: 5rem;
}

.account-content {
  width: 756px;

  &__logo {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-bottom: 3rem;

    img {
      width: 155px;
      object-fit: cover;
    }
  }

  &__detail {
    display: flex;
    align-items: stretch;
    border: 1px solid var(--color-light);
    border-radius: 0.2px;
    height: 50rem;

    & > * {
      width: 50%;
    }

    &-image {
      background: var(--color-main-light);
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 80%;
        max-width: 100%;
        object-fit: cover;
      }
    }

    &-form {
      background: #fff;
      padding: 2rem;
      // padding-top: 6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-title {
        font-size: 2.8rem;
        // font-family: var(--ff-mono);
        text-transform: uppercase;
        margin-bottom: 4rem;
        font-weight: var(--fw-bd);
        text-align: center;
      }

      &-recaptcha {
        margin-bottom: 2rem;
      }

      &-footer {
        margin-bottom: 3rem;
        // text-align: center;

        a {
          font-size: 1.2rem;
        }
      }
    }
  }
}
