.dob-wrapper {
  padding-top: 13rem;
  padding-bottom: 5rem;
  width: 80%;
  max-width: 1140px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dob-main-content {
  padding-top: 5rem;
  text-align: initial;
  width: 100%;
  position: relative;
}

.dob-header {
  margin-bottom: 5rem;
  max-width: 586px;

  &-title {
    // margin-bottom: 1rem;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 1.5;
  }
}
.dob-steps-container {
  max-width: 576px;
  margin-inline: auto;
}

.dob-steps {
  width: 100%;
}

.dob-step-list {
  display: flex;
  justify-content: space-between;
}

.dob-step-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 65%;
      background: var(--color-light);
      top: 20%;
      left: 100%;
      transform: translateX(-50%);
      margin-top: -0.5rem;
      z-index: 2;
    }
  }

  &.active {
    .dob-step-item-index {
      border: 2px solid var(--color-main);
      color: var(--color-main);
    }
  }

  &.validate {
    .dob-step-item-index {
      background: var(--color-success-light);
      color: var(--color-success);

      i {
        font-size: 1.5rem;
        font-weight: 700;
      }
    }

    .dob-step-item-content {
      color: var(--color-secondary);
    }
  }
  &.active.validate {
    .dob-step-item-index {
      border: 2px solid var(--color-main);
      color: var(--color-main);
      background: transparent;
    }
  }
}

.dob-step-item-index {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid var(--color-light);
  margin-bottom: 2rem;
  font-weight: 700;
}

.dob-step-item-content {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.dob-step-item-label {
  margin-bottom: 1rem;
  font-size: 1.6rem;
}

.dob-step-item-detail {
  font-size: 1.3rem;
  max-width: 70%;
  margin: 0 auto;
  line-height: 1.7;
}

// DOB content
.dob-main-content {
  &-wrapper {
    width: 65%;
    margin: 0 auto;
    position: relative;

    &.center {
      text-align: center;

      .dob-main-content-title {
        max-width: 557px;
        margin: 0 auto;
        font-size: 3rem;
      }

      img {
        width: 200px;
        margin-bottom: 4rem;
        margin-top: 4rem;
      }

      p {
        margin: 0 auto;
        max-width: 657px;
        margin-bottom: 2rem;
      }
    }

    &.full {
      width: 80%;
    }

    &.full-100 {
      width: 100%;
    }
  }

  &-header {
    margin-bottom: 2rem;
    text-align: center;
  }

  &-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  &-sub-title {
    margin-bottom: 1rem;
  }
}

.dob-loader {
  width: 100%;
  height: calc(100% - 25rem);
  background: #f1f5f9;
  position: fixed;
  top: 35rem;
  left: 0;
  opacity: 0.85;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 89;
}

.dob-content-detail {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 2rem;
}

// Search
.card-search {
  background: #fff;
  padding: 1.5rem;
  margin-bottom: 3rem;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;

  &-loader {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.16);
  }
}


// Choice list
.choice-list {
  width: 100%;
}

.choice-header {
  display: flex;
  background-color: var(--color-main-light);
  color: var(--color-main);
  font-weight: var(--fw-lg);
  padding: 1.65rem 1rem;
  justify-content: space-between;
  text-align: left;
  text-transform: uppercase;

  &-item {
    flex: 1;
    width: calc(100%/4);
    flex-basis: calc(100%/4);
    padding-left: 1.2rem;

    &.fixed {
      flex: 0;
    }

    &.right {
      text-align: right;
    }

    &.grow-2 {
      flex: 2;
    }

    &.grow-1 {
      flex: 1;
    }
  }
}

.choice-box {
  padding: 1.45rem 1rem;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: grab;
  display: flex;
  width: 100%;
  align-items: center;
  vertical-align: middle;
  justify-content: space-between;
  text-align: left;
  transition: all .2s;

  &:hover {
    background: var(--color-secondary-light);
  }

  &:not(:last-child)  {
    margin-bottom: 1.2rem;
  }
}

.choice-item {
  flex: 1;
  width: calc(100%/4);
  flex-basis: calc(100%/4);
  box-sizing: border-box;
  padding-left: 1.2rem;
  line-height: 1.7;
  font-size: 1.2rem;

  &.fixed {
    flex: 0;
  }

  &.right {
    text-align: right;
  }

  &.grow-2 {
    flex: 2;
  }

  &.grow-1 {
    flex: 1;
  }
}

.cdk-drag-preview {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.dob-content-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background: #f1f5f9;
  z-index: 40;
}
