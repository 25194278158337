.p-paginator {
  display: flex;
}

.p-paginator .p-paginator-current {
  flex: 1;
  display: inline-block;
  line-height: 1.8;
  padding: 0;
  margin: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  border-color: var(--color-main);
  color: var(--color-main);
}

.paginator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.paginator-text {
  color: var(--color-main);
  opacity: .75;
}

.paginator-button {
  background: transparent;
  color: var(--color-main);
  border: none;

  span {
    font-size: 1.4rem;
  }

  &:hover {
    transition: all .2s;
    background: var(--color-main-lighter)!important;
    color: var(--color-main)!important;
  }
}
