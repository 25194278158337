
.validation {

  &-search {
    background-color: #fff;
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 10px;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }

  &-search-block {
    display: flex;
    align-items: center;
    position: relative;

    button {
      position: absolute;
      right: 10px;
    }

    #field_matricule {
      width: 100%;
      input {
        flex: 1;
        width: 100%;
        padding: 2.4rem !important;
      }
    }

  }

  &-search-suggestion {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-radius: 4px;
    cursor: pointer;
    max-height: 300px;
    overflow-y: auto;
    display: inline-block;

    &-item {
      padding: 1.5rem 2rem;
      transition: background-color 0.2s;
      background: #fff;

      &:hover {
        background-color: var(--color-white);
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--color-light);
      }

      &-matricule {
        margin-bottom: .8rem;
        font-weight: 700;
        color: var(--color-main);
      }
    }
  }

  &-search-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.3rem;
  }

  &-search-criteria {
    display:flex;
    margin-bottom: 1.3rem;

    & > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  &-content {
    // padding: 4rem;
  }

  &-content-search {
    margin-top: 10rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-content-search-icon {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-light);
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin-bottom: 1rem;


    i {
      color: var(--color-main);
      font-size: 2.5rem;
    }
  }

  &-content-detail {
    padding: 4rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  &-content-detail-wrapper {
    // flex: 1;

    &-left,
    &-right {
      border-radius: 5px;
    }

    &-left {
      padding: 2rem;
      order: 1;
    }

    &-right {
      order: 2;
      padding: 0 2rem;
    }
  }

  &-content-detail-box {
    border-radius: 5px;
    height: 100%;

    &:not(:last-child) {
      margin-bottom: 4rem;
    }

    &-title {
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }


    &-icon {
      width: 80px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-white);
      background: var(--color-main-light);
      border-radius: 20px;

      i {
        font-size: 1.5rem;
        color: var(--color-main);
      }
    }
  }

  &-content-detail-form {

    form {
      background: #fff;
      padding: 1rem 2rem;
    }
  }

  &-content-detail-box-checklist {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;

    &-item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.3rem 1rem;
      border: 1px solid var(--color-light);
      border-radius: 4px;
      background-color: var(--color-main-lighter);
      background-color: #fff;

      label {
        flex: 1;
        cursor: pointer;
      }


      &-value {
        display: flex;
        align-items: center;

        & > *:not(:last-child) {
          margin-right: 1rem;
        }


      }
    }

    &-action {
      button {
        // width: 100%;
        float: right;
      }
    }
  }


  // VALIDATION STATUS
  &-content-detail-box-checklist-status {
    display: grid;
    place-items: center;
    height: calc(100% - 4rem);

    &-no-ok,
    &-ok {
      display: flex;
      flex-direction: column;
      align-items: center;

      i {
        margin-bottom: 2rem;
        font-size: 10rem;
      }

      span {
        font-weight: 700;
        font-size: 2.5rem;
        text-transform: uppercase;
      }
    }

    &-ok {
      color: var(--color-success);
    }

    &-no-ok {
      color: var(--color-danger);
    }
  }
}

.validation-checklist {
  display: flex;
  padding: 0 2rem;

  &-title {
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-light);
    }
  }
}
