.mb-2 {
  margin-bottom: 2rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-8 {
  margin-bottom: 8rem;
}

.color {
  &-danger {
    color: var(--color-danger);
  }
  &-success {
    color: var(--color-success);
  }
  &-main {
    color: var(--color-main);
  }
  &-warning {
    color: var(--color-warning);
  }
  &-info {
    color: var(--color-info);
  }
  &-secondary {
    color: var(--color-secondary);
  }
  &-white {
    color: var(--color-white);
  }
  &-black {
    color: var(--color-black);
  }
}

.p-inline {
  display: flex;

  & > *:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.p-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  & > * {
    flex: 1;
  }

  & > *:not(:last-child) {
    margin-right: 4rem;
    margin-bottom: 0 !important;
  }
}

.link {
  display: inline-block;
  text-decoration: underline;
  transition: all 0.2s;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &-main {
    color: var(--color-main);
  }

  &-danger {
    color: var(--color-danger);
  }
}

.chip-danger .p-chip,
.chip-success .p-chip,
.chip-warning .p-chip {
  color: var(--color-white);
  // padding: 4px;
}

.chip-danger .p-chip {
  background-color: var(--color-danger);
}
.chip-success .p-chip {
  background-color: var(--color-success);
}
.chip-warning .p-chip {
  background-color: var(--color-warning);
}

.state {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 1.2rem;

  &-success {
    color: var(--color-success);
  }

  &-main {
    color: var(--color-main);
  }

  &-danger {
    color: var(--color-danger);
  }

  &-secondary {
    color: var(--color-secondary);
  }
  &-white {
    color: var(--color-white);
  }
  &-black {
    color: var(--color-black);
  }

  &-warning {
    color: var(--color-warning);
  }
  &-info {
    color: var(--color-info);
  }
}

// chip
.chip {
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.3rem 1rem;
  // cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 0 0 rgba($color: #000000, $alpha: 0.5);
  transition: text-decoration 0.2s;
  font-weight: var(--fw-bd);

  &.rounded {
    border-radius: 5px;
  }

  &-main {
    background: var(--color-main-light);
    color: var(--color-main);
  }

  &-warning {
    background: var(--color-warning-light);
    color: var(--color-warning);
  }

  &-success {
    background: var(--color-success-light);
    color: var(--color-success);
  }

  &-danger {
    background: var(--color-danger-light);
    color: var(--color-danger);
  }

  &-info {
    background: var(--color-info-light);
    color: var(--color-info);
  }

  &-secondary {
    background: var(--color-secondary-light);
    color: var(--color-secondary);
  }

  .pi {
    font-size: 1.4rem;
  }

  &:hover {
    // text-decoration: underline;
  }
}

// Message
.message {
  border-radius: 5px;
  padding: 2rem;

  &-success {
    background: var(--color-success);
    color: var(--color-white);
  }
}

// TEXT
.text {
  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }
}

// switch

.switch-success {
  .p-inputswitch .p-inputswitch-slider {
    background: var(--color-success);
  }
}

.switch-danger {
  .p-inputswitch .p-inputswitch-slider {
    background: var(--color-danger);
  }
}

.pi-button {
  margin-right: 0.85rem;
}

// ALERT MESSAGE
.alert {
  display: block;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background: #fff;
  padding: 2rem;
  border-radius: 4px;
  font-weight: var(--fw-bd);

  &-warning {
    background: var(--color-warning-light);
    color: var(--color-warning);
  }

  &-message {
    background: #fff3cd;
    color: #856404;
  }
}

.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  & > * {
    flex: 1;
  }
}

.flex-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 2rem;
  width: 100%;

  & > * {
    flex: 1;
  }
}


.no-pointer {
  pointer-events: none;
}
